












import Vue from "vue";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";

export default Vue.extend ({
    components: {
        PageTitleHeader
    },
    computed: {
        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("tradeshow") || {};
        },
        textHeader(): string {
            return this.pageConfig?.textHeader;
        }
    }
})
